export namespace Constants {
	export const loginStatus = [
		{ label: 401, value: "Login/Senha inválidos" },
		{ label: 502, value: "OPS! Ocorreu algum problema, tente novamente." }
	];

	export const textRedirectLogin =
		"Estamos redirecionando você para a página de login.";

	export const ErrorMessages = {
		requiredField: "Campo obrigatório",
		validEmail: "Deve ser um email válido",
		mustBeCpfIfPhysical: "Deve ser um CPF para pessoas físicas",
		mustBeCnpjIfJuridical: "Deve ser um CNPJ para pessoas jurídicas",
		mustNotBePlaceholderDocument: "Documento inválido",
		postalCodeApiError:
			"Erro ao acessar dados do CEP. Preencha os dados manualmente.",
		fillAllRequiredDocuments:
			"Necessário anexar todos os documentos obrigatórios.",
		mustBeInsideHighwayConcession:
			"Valores devem ser dentro dos limites da concessão",
		atLeastOneDirection: "É necessário preencher ao menos 1 sentido.",
		initialMustBeBeforeFinal:
			"Valor inicial deve ser menor do que o valor final.",
		greaterThanZero: "Valor deve ser maior do que 0.",
		lessThan1000: "Valor deve ser menor do que 1000.",
		beforeToday: "Data deve ser anterior ao dia atual."
	} as const;

	export enum UserDocumentType {
		CPF = "CPF",
		CNPJ = "CNPJ"
	}

	export enum FederalUnits {
		AC = "AC",
		AL = "AL",
		AP = "AP",
		AM = "AM",
		BA = "BA",
		CE = "CE",
		DF = "DF",
		ES = "ES",
		GO = "GO",
		MA = "MA",
		MS = "MS",
		MT = "MT",
		MG = "MG",
		PA = "PA",
		PB = "PB",
		PR = "PR",
		PE = "PE",
		PI = "PI",
		RJ = "RJ",
		RN = "RN",
		RS = "RS",
		RO = "RO",
		RR = "RR",
		SC = "SC",
		SP = "SP",
		SE = "SE",
		TO = "TO"
	}

	export const FederalUnitsRecord: Record<FederalUnits, string> = {
		AC: "Acre",
		AL: "Alagoas",
		AP: "Amapá",
		AM: "Amazonas",
		BA: "Bahia",
		CE: "Ceará",
		DF: "Distrito Federal",
		ES: "Espirito Santo",
		GO: "Goiás",
		MA: "Maranhão",
		MS: "Mato Grosso do Sul",
		MT: "Mato Grosso",
		MG: "Minas Gerais",
		PA: "Pará",
		PB: "Paraíba",
		PR: "Paraná",
		PE: "Pernambuco",
		PI: "Piauí",
		RJ: "Rio de Janeiro",
		RN: "Rio Grande do Norte",
		RS: "Rio Grande do Sul",
		RO: "Rondônia",
		RR: "Roraima",
		SC: "Santa Catarina",
		SP: "São Paulo",
		SE: "Sergipe",
		TO: "Tocantins"
	} as const;

	export const federalUnitCodeOptions = Object.entries(
		Constants.FederalUnitsRecord
	).map(([unitCode]) => ({
		label: unitCode,
		value: unitCode
	}));

	export enum UserRoleGroups {
		SOLICITANTE = "SOLICITANTE",
		FOCAL = "FOCAL",
		CENTRAL = "CENTRAL",
		ENGENHARIA = "ENGENHARIA"
	}

	export const AdminRole = "ADMINISTRADOR";

	export enum ReadableUserRoleGroups {
		SOLICITANTE = "Solicitante",
		FOCAL = "Focal",
		CENTRAL = "Central",
		ENGENHARIA = "Engenharia"
	}

	export function getReadableGroupName(
		role: UserRoleGroups | null
	): ReadableUserRoleGroups {
		if (role === null) return ReadableUserRoleGroups.SOLICITANTE;
		return ReadableUserRoleGroups[role];
	}

	export const federalUnitNameOptions = Object.entries(
		Constants.FederalUnitsRecord
	).map(([unitCode, unitName]) => ({
		label: `${unitCode} - ${unitName}`,
		value: unitCode
	}));

	// TODO: remover e substituir pelos fileExtensions da API
	export const acceptedDocumentFormats = [
		"pdf",
		"jpg",
		"jpeg",
		"png",
		"dwg",
		"xls",
		"xlsx",
		"doc",
		"docx",
		"kmz"
	];

	export const acceptedDocumentFormatsDescription = `${acceptedDocumentFormats
		.slice(0, acceptedDocumentFormats.length - 1)
		.map((s) => s.toUpperCase())
		.join(", ")} e ${acceptedDocumentFormats[
		acceptedDocumentFormats.length - 1
	].toUpperCase()}`;

	export const MIN_APPLICATION_CANCELLATION_LENGTH = 50;
	export const MIN_APPLICATION_DESCRIPTION_LENGTH = 50;

	export const ONE_MB = 1024 * 1024; // 1MB
	export const ONE_GB = 1000 * ONE_MB;
	// export const MAX_DOCUMENT_SIZE = 2 * ONE_GB;
	export const MAX_DOCUMENT_SIZE = 350 * ONE_MB;

	export const ACCESS_TOKEN_COOKIE = "access_token";
	export const INITIAL_PAGE_INDEX = 1;
	export const NOT_FOUND_INDEX = -1;

	const LOCALE_DATE_FORMAT = "dd/MM/yyyy";
	const LOCALE_DATE_SHORT_YEAR_FORMAT = "dd/MM/yy";
	const LOCALE_TIME_FORMAT = "HH:mm";
	const LOCALE_DATETIME_FORMAT = `${LOCALE_DATE_FORMAT} '-' ${LOCALE_TIME_FORMAT}`;
	const DEFAULT_DATE_FORMAT = "yyyy-MM-dd";
	export const date = {
		LOCALE_DATE_FORMAT,
		LOCALE_TIME_FORMAT,
		LOCALE_DATETIME_FORMAT,
		LOCALE_DATETIME_WITH_SECONDS_FORMAT: `${LOCALE_DATETIME_FORMAT}:ss`,
		DEFAULT_DATE_FORMAT,
		LOCALE_DATE_SHORT_YEAR_FORMAT
	} as const;

	export const ONE_REM = 16;
	export const zIndexes = {
		select: 9999,
		tooltip: 10000
	} as const;
}
