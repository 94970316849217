import { Constants } from "appConstants";
import ApplicationNewRecordOptions, {
	ApplicationEventOnlyFormType,
	ApplicationNewRecordOption
} from "appConstants/ApplicationNewRecordOptions";
import {
	Button,
	Input,
	Label,
	MultiFileDisplay,
	SelectForm,
	TextArea
} from "components";

import { IcoUpload } from "assets/icons";
import colors from "colors";
import MultiFileInput from "components/MultiFileInput";
import ToastWithTitle from "components/ToastWithTitle";
import { baseConfig } from "config";
import { useFormik } from "formik";
import useFileUploadQueue from "hooks/useFileUploadQueue";
import useUserToken from "hooks/useUserToken";
import { AnalysisStatusCodeRequest, ApplicationEventType } from "models/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ApplicationService } from "services/applicationService";
import { DocumentLike, RegisterEventRequest } from "services/types";
import { RootState } from "store";
import {
	initialStateNewRecord,
	reset,
	setValues
} from "store/features/newRecord/slice";
import * as Yup from "yup";
import { CancelNewRecordModal } from "../../components";
import { TabComponentProps } from "../../types";
import "./styles.scss";

export type NewRecordForm = {
	action?: ApplicationNewRecordOption | null;
	status?: { label: string; value: keyof typeof ApplicationEventType } | null;
	comment: string;
	commentDocuments: DocumentLike[];
	subject?: string;
	response?: string;
	responseDocuments: DocumentLike[];
};

export default function ApplicationPageNewRecord({
	application,
	reloadApplication
}: TabComponentProps) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const stateValues = useSelector((state: RootState) => state.newRecord);
	const [isOpenModalCancel, setIsOpenModalCancel] = useState(false);
	const [isLoadingSave, setIsLoadingSave] = useState(false);
	const applicationService = useMemo(ApplicationService.getInstance, []);
	const internalUser = useUserToken();
	const validationSchema = Yup.object().shape({
		action: Yup.object().shape({
			value: Yup.string().required()
		}),
		status: Yup.object()
			.nullable()
			.when("action", {
				is: (action: any) => action?.status && action?.status?.length > 0,
				then: Yup.object().shape({
					value: Yup.string().required()
				})
			}),
		comment: Yup.string().required(),
		subject: Yup.string()
			.max(251, "O campo Assunto deve ter no máximo 251 caracteres")
			.when("action", {
				is: (action: any) =>
					action?.value === ApplicationEventType.RESPOSTA_PARA_SOLICITANTE,
				then: Yup.string().required()
			}),
		response: Yup.string().when("action", {
			is: (action: any) =>
				action?.value === ApplicationEventType.RESPOSTA_PARA_SOLICITANTE,
			then: Yup.string().required()
		})
	});

	const getDefaultDataRequest = (
		values: NewRecordForm
	): RegisterEventRequest => {
		const actionIsOnlyFormType = Object.values(
			ApplicationEventOnlyFormType
		).includes(values.action?.value as ApplicationEventOnlyFormType);
		const eventType = actionIsOnlyFormType
			? values.status?.value
			: values.action!.value;

		const data = {
			event: {
				type: eventType as ApplicationEventType,
				description: values.comment
			},
			documents: values.commentDocuments
		};
		return data;
	};

	const registerEvent = async (values: NewRecordForm) => {
		if (
			values.action!.value !== ApplicationEventType.RESPOSTA_PARA_SOLICITANTE
		) {
			const data = getDefaultDataRequest(values);
			await applicationService.registerEvent(application!.id, data);
		} else {
			const dataComment: RegisterEventRequest = {
				event: {
					type: ApplicationEventType.ADICIONAR_COMENTARIO,
					description: values.comment
				},
				documents: values.commentDocuments
			};
			await applicationService.registerEvent(application!.id, dataComment);

			const dataResponseToApplicant: RegisterEventRequest = {
				event: {
					type: ApplicationEventType.RESPOSTA_PARA_SOLICITANTE,
					title: values.subject,
					description: values.response
				},
				documents: values.responseDocuments,
				analysisStatusCode: values.status!
					.value as keyof typeof AnalysisStatusCodeRequest
			};
			await applicationService.registerEvent(
				application!.id,
				dataResponseToApplicant
			);
		}
	};

	const formik = useFormik({
		initialValues: stateValues as NewRecordForm,
		validationSchema,
		onSubmit: async (values) => {
			setIsLoadingSave(true);
			try {
				await registerEvent(values);
				dispatch(reset());
				toast.success(
					<ToastWithTitle
						title="Registro criado com sucesso"
						description="Essa ação foi registrada no histórico."
					/>
				);
				reloadApplication();
				navigate(`../solicitacao/${application?.id}/historico`);
			} catch {
				toast.error(
					<ToastWithTitle
						title="Ops! Erro ao registrar"
						description="Essa ação não foi salva no histórico"
					/>
				);
			}
			setIsLoadingSave(false);
		}
	});
	const setCommentDocuments = useCallback(
		(newDocs: DocumentLike[]) =>
			formik.setFieldValue("commentDocuments", newDocs),
		[formik.setFieldValue]
	);
	const setResponseDocuments = useCallback(
		(newDocs: DocumentLike[]) =>
			formik.setFieldValue("responseDocuments", newDocs),
		[formik.setFieldValue]
	);
	const fileUploadOptions = {
		maxFileCount: baseConfig.maxSubmissionApplicationFiles,
		acceptedFormats: Constants.acceptedDocumentFormats,
		maxByteSize: Constants.MAX_DOCUMENT_SIZE
	};
	const commentDocumentsUpload = useFileUploadQueue(
		formik.values.commentDocuments,
		setCommentDocuments,
		fileUploadOptions
	);
	const responseDocumentsUpload = useFileUploadQueue(
		formik.values.responseDocuments,
		setResponseDocuments,
		fileUploadOptions
	);

	const actionOptions = useMemo(
		() =>
			application?.currentUserResponsibleGroup
				? ApplicationNewRecordOptions[application?.currentUserResponsibleGroup]
				: [],
		[application]
	);

	useEffect(() => {
		dispatch(
			setValues({
				...formik.values,
				applicationId: application?.id
			})
		);
	}, [formik.values]);

	useEffect(() => {
		if (
			application &&
			application?.currentUserResponsibleEmail !== internalUser?.email
		) {
			navigate(`../solicitacao/${application.id}/detalhes`);
		}

		if (!!application?.id && application?.id !== stateValues.applicationId) {
			formik.setValues(initialStateNewRecord);
		}
	}, [application?.id]);

	const resetOnActionChange = () => {
		formik.setValues({
			...formik.values,
			status: null,
			subject: "",
			response: "",
			responseDocuments: []
		});
	};

	const fileCountCommentDocuments =
		formik.values.commentDocuments.length +
		commentDocumentsUpload.notUploadedDocuments.length +
		commentDocumentsUpload.documentUploads.length;

	const fileCountResponseDocuments =
		formik.values.responseDocuments.length +
		responseDocumentsUpload.notUploadedDocuments.length +
		responseDocumentsUpload.documentUploads.length;

	const hasDocumentsWithError = useMemo(
		() =>
			formik.values.commentDocuments.some((doc) => !!doc.error) ||
			formik.values.responseDocuments.some((doc) => !!doc.error),
		[formik.values.commentDocuments, formik.values.responseDocuments]
	);

	const hasPendingDocuments = useMemo(
		() =>
			commentDocumentsUpload.notUploadedDocuments.length > 0 ||
			commentDocumentsUpload.documentUploads.length > 0 ||
			responseDocumentsUpload.notUploadedDocuments.length > 0 ||
			responseDocumentsUpload.documentUploads.length > 0 ||
			hasDocumentsWithError,
		[commentDocumentsUpload, responseDocumentsUpload, hasDocumentsWithError]
	);

	const disableMultiFileInput =
		!formik.values.action ||
		fileCountCommentDocuments >= baseConfig.maxSubmissionApplicationFiles;
	const disableReplyMultiFileInput =
		formik.values.action?.value !==
			ApplicationEventType.RESPOSTA_PARA_SOLICITANTE ||
		fileCountResponseDocuments >= baseConfig.maxSubmissionApplicationFiles;

	return (
		<div className="pb-5">
			<div className="new-record-container">
				<form onSubmit={formik.handleSubmit} className="new-record-form">
					<div className="flex gap-6 pb-8">
						<SelectForm
							name="action"
							label="Ação:"
							value={formik.values.action}
							touched={formik.touched.action}
							options={actionOptions}
							onBlur={formik.handleBlur}
							setFieldValue={formik.setFieldValue}
							isClearable={false}
							onChange={resetOnActionChange}
							className="max-w-[375px]"
						/>
						<SelectForm
							name="status"
							label="Status:"
							value={formik.values.status}
							touched={formik.touched.status}
							options={formik.values.action?.status || []}
							onBlur={formik.handleBlur}
							setFieldValue={formik.setFieldValue}
							isDisabled={!formik.values.action?.status}
							isClearable={false}
							className="max-w-[375px]"
						/>
					</div>
					<div className="pb-2">
						<Label isRequired={false} showOptionalIndicator={false}>
							Comentário interno:
						</Label>
						<TextArea
							label="Comentário interno:"
							placeholder="Digite seu comentário"
							name="comment"
							value={formik.values.comment}
							onChange={formik.handleChange}
							touched={formik.touched.comment}
							onBlur={formik.handleBlur}
							maxLength={500}
							disabled={!formik.values.action}
						/>
					</div>
					<div className="pb-6 flex flex-col gap-4">
						<div className="w-fit">
							<MultiFileInput
								handleDrop={commentDocumentsUpload.handleDrop}
								handleInputChange={commentDocumentsUpload.handleInputChange}
								disabled={disableMultiFileInput}
							>
								<IcoUpload
									size="24"
									color={
										disableMultiFileInput
											? colors.neutral["low-300"]
											: undefined
									}
								/>
								<span className="text-nowrap">Anexar arquivos</span>
							</MultiFileInput>
						</div>
						<MultiFileDisplay
							documentsUploaded={formik.values.commentDocuments}
							documentsInUpload={commentDocumentsUpload.documentUploads}
							documentsNotUploaded={commentDocumentsUpload.notUploadedDocuments}
							setDocumentsUploaded={setCommentDocuments}
							setDocumentsNotUploaded={
								commentDocumentsUpload.setNotUploadedDocuments
							}
							showFileCountLimit={commentDocumentsUpload.showFileCountLimit}
							columnsDisplay={5}
						/>
					</div>
					{application?.currentUserResponsibleGroup ===
						Constants.UserRoleGroups.FOCAL && (
						<>
							<Input
								label="Assunto:"
								placeholder="Escrever assunto"
								name="subject"
								value={formik.values.subject}
								disabled={
									formik.values.action?.value !==
									ApplicationEventType.RESPOSTA_PARA_SOLICITANTE
								}
								touched={formik.touched.subject}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								maxLength={251}
								className="max-w-[375px] pb-4"
							/>
							<div className="pb-2">
								<Label isRequired={false} showOptionalIndicator={false}>
									Resposta ao interessado:
								</Label>
								<TextArea
									label="Resposta ao interessado:"
									placeholder="Digite sua mensagem"
									name="response"
									value={formik.values.response}
									onChange={formik.handleChange}
									touched={formik.touched.response}
									onBlur={formik.handleBlur}
									maxLength={500}
									disabled={
										formik.values.action?.value !==
										ApplicationEventType.RESPOSTA_PARA_SOLICITANTE
									}
								/>
							</div>
							<div className="pb-6 flex flex-col gap-4">
								<div className="w-fit">
									<MultiFileInput
										handleDrop={responseDocumentsUpload.handleDrop}
										handleInputChange={
											responseDocumentsUpload.handleInputChange
										}
										disabled={disableReplyMultiFileInput}
									>
										<IcoUpload
											size="24"
											color={
												disableReplyMultiFileInput
													? colors.neutral["low-300"]
													: undefined
											}
										/>
										<span className="text-nowrap">Anexar arquivos</span>
									</MultiFileInput>
								</div>
								<MultiFileDisplay
									documentsUploaded={formik.values.responseDocuments}
									documentsInUpload={responseDocumentsUpload.documentUploads}
									documentsNotUploaded={
										responseDocumentsUpload.notUploadedDocuments
									}
									setDocumentsUploaded={setResponseDocuments}
									setDocumentsNotUploaded={
										responseDocumentsUpload.setNotUploadedDocuments
									}
									showFileCountLimit={
										responseDocumentsUpload.showFileCountLimit
									}
									columnsDisplay={5}
								/>
							</div>
						</>
					)}
				</form>
				<div className="footer-buttons">
					<Button
						hierarchy="secondary"
						size="medium"
						onClick={() => setIsOpenModalCancel(true)}
					>
						Cancelar
					</Button>
					<Button
						hierarchy="primary"
						size="medium"
						onClick={formik.handleSubmit}
						disabled={!(formik.dirty && formik.isValid) || hasPendingDocuments}
						isLoading={isLoadingSave}
					>
						Confirmar
					</Button>
				</div>
			</div>
			<CancelNewRecordModal
				isOpen={isOpenModalCancel}
				onClose={() => setIsOpenModalCancel(false)}
				onClickConfirm={() => {
					formik.setValues(initialStateNewRecord);
					setIsOpenModalCancel(false);
				}}
			/>
		</div>
	);
}
