import { Constants } from "appConstants";
import {
	IcoAccessibilityQuestionCircle,
	IcoAdd,
	IcoArrowDown,
	IcoAttachment,
	IcoClose,
	IcoFeedbackCheck,
	IcoFeedbackError,
	IcoFolder,
	IcoTrash
} from "assets/icons";
import clsx from "clsx";
import colors from "colors";
import Button from "components/Button";
import MultiFileInput from "components/MultiFileInput";
import { FormikErrors, FormikTouched } from "formik";
import { DownloadHelper, ErrorHelper } from "helpers";
import useFileUploadQueue from "hooks/useFileUploadQueue";
import { useCallback, useMemo, useState } from "react";
import { Tooltip } from "react-tooltip";
import { ApplicationDocumentService } from "services/applicationDocument";
import {
	ApplicationFormDocumentFolder,
	DocumentLike,
	FileExtensionData
} from "services/types";

const folderDocumentListItemClassBase =
	"w-full flex items-center justify-center px-4 py-2 border-t border-neutral-high-200";

type FolderDocumentDisplayState =
	| "readonly"
	| "queued"
	| "uploading"
	| "error"
	| "success";

function LoadingWheel({
	uploadProgress,
	size = "20"
}: Readonly<{ uploadProgress: number; size?: string }>): JSX.Element {
	const numericSize = Number(size);
	return (
		<div
			className="rounded-full flex items-center justify-center"
			style={{
				width: `${size}px`,
				height: `${size}px`,
				backgroundImage: `conic-gradient(${
					colors.neutral["low-pure-500"]
				} 0turn ${(uploadProgress * 360) / 100}deg, ${
					colors.neutral["high-200"]
				} 0turn)`
			}}
		>
			<div
				className="rounded-full bg-neutral-high-pure-50"
				style={{
					width: `${numericSize - 2}px`,
					height: `${numericSize - 2}px`
				}}
			/>
		</div>
	);
}

const folderDocumentDisplayIcons: Record<
	FolderDocumentDisplayState,
	React.FC<any> | null
> = {
	readonly: null,
	queued: IcoAttachment,
	uploading: LoadingWheel,
	error: IcoFeedbackError,
	success: IcoFeedbackCheck
};

export function FolderDocumentDisplay({
	documentName,
	blobName,
	error,
	state,
	uploadProgress,
	cancelUpload,
	removeDocument,
	fileExtensions
}: Readonly<{
	documentName: string;
	blobName?: string;
	error?: string;
	state: FolderDocumentDisplayState;
	uploadProgress?: number;
	cancelUpload?: () => void;
	removeDocument?: () => void;
	fileExtensions?: FileExtensionData[] | null;
}>): JSX.Element {
	const Icon = folderDocumentDisplayIcons[state];
	const showRemoveDocument = ["success", "error"].includes(state);
	const showCancelUpload = state === "queued";
	const hasControls = showRemoveDocument || showCancelUpload;
	const applicationDocumentService = ApplicationDocumentService.getInstance();
	const performDownload = useCallback(
		() =>
			blobName &&
			applicationDocumentService.download(blobName).then((response) => {
				const { downloadUrl } = response;
				DownloadHelper.performDownload(downloadUrl, documentName);
			}),
		[]
	);
	const errorIdentifier = useMemo(() => Math.trunc(Math.random() * 100000), []);
	const errorTooltipText = ErrorHelper.getFileUploadErrorTooltipText(
		fileExtensions ?? []
	);
	return (
		<div className="w-full h-full flex flex-row gap-4 items-center justify-between text-sm">
			<div
				className={clsx(
					"h-full flex items-center gap-4",
					hasControls ? "w-[calc(100%-5rem)]" : "w-full"
				)}
			>
				{Icon && (
					<div className="flex items-center justify-center w-5 h-5">
						<Icon size="20" uploadProgress={uploadProgress} />
					</div>
				)}
				<div
					className={clsx(
						"flex flex-col h-full grow justify-center",
						Icon ? "max-w-[calc(100%-2.25rem)]" : "max-w-full"
					)}
				>
					{state === "readonly" && blobName ? (
						<Button
							kind="link"
							size="small"
							hierarchy="ghost"
							className="w-fit m-0 p-0 max-w-full"
							childrenWrapperClassName="w-fit m-0 p-0 max-w-full"
							onClick={performDownload}
						>
							<span className="underline text-left text-ellipsis overflow-hidden max-w-full">
								{documentName}
							</span>
						</Button>
					) : (
						<span className="text-ellipsis overflow-hidden max-w-full">
							{documentName}
						</span>
					)}
					{uploadProgress !== undefined && <span>{uploadProgress}%</span>}
					{error && (
						<>
							<Tooltip
								anchorSelect={`#error-message-span-${errorIdentifier}`}
								place="top"
							>
								<div className="max-w-[90vw]">
									{errorTooltipText.map((t) => (
										<p key={t}>{t}</p>
									))}
								</div>
							</Tooltip>
							<span
								id={`error-message-span-${errorIdentifier}`}
								className="text-xs w-fit flex flex-column gap-[2px] cursor-default"
							>
								<span>{error}</span>
								<IcoAccessibilityQuestionCircle size="16" />
							</span>
						</>
					)}
				</div>
			</div>
			<div className="flex items-center gap-4">
				{showRemoveDocument && (
					<Button kind="icon" hierarchy="ghost" onClick={removeDocument}>
						<IcoTrash />
					</Button>
				)}
				{showCancelUpload && (
					<Button kind="icon" hierarchy="ghost" onClick={cancelUpload}>
						<IcoClose />
					</Button>
				)}
			</div>
		</div>
	);
}

export default function DocumentFolderInput({
	value,
	setValue,
	errors,
	touched,
	folderType,
	folderName,
	expectedFormats,
	minDocumentCount,
	fileExtensions,
	readonly = false,
	initiallyExpanded
}: Readonly<{
	value: ApplicationFormDocumentFolder | null;
	setValue?: (newValue: ApplicationFormDocumentFolder) => void;
	errors?: string | FormikErrors<ApplicationFormDocumentFolder>;
	touched?: boolean | FormikTouched<ApplicationFormDocumentFolder>;
	folderType: string;
	folderName: string;
	expectedFormats?: string;
	minDocumentCount?: number;
	fileExtensions: FileExtensionData[] | null;
	readonly?: boolean;
	initiallyExpanded?: boolean;
}>): JSX.Element {
	const [expanded, setExpanded] = useState<boolean>(
		initiallyExpanded ?? !!value?.documents.length
	);

	const errorMessage =
		!errors || typeof errors === "string"
			? errors
			: Object.values(errors).join("; ");

	const uploadedDocuments = value?.documents ?? [];
	const setFolderDocuments = (newDocuments: DocumentLike[]) => {
		const newFolderData: ApplicationFormDocumentFolder = {
			documents: newDocuments,
			interventionDocumentFolderType: folderType
		};
		setValue?.(newFolderData);
	};
	const {
		documentUploads,
		uploadProgress,
		notUploadedDocuments,
		handleInputChange,
		handleDrop,
		setNotUploadedDocuments
	} = useFileUploadQueue(uploadedDocuments, setFolderDocuments, {
		maxFileCount: undefined,
		acceptedFormats: undefined,
		maxByteSize: Constants.MAX_DOCUMENT_SIZE
	});

	const cancelUpload = useCallback(
		(idxToRemove: number) => {
			setNotUploadedDocuments(
				notUploadedDocuments.filter((_, index) => idxToRemove !== index)
			);
		},
		[notUploadedDocuments, setNotUploadedDocuments]
	);

	const removeDocument = useCallback(
		(idxToRemove: number) => {
			setFolderDocuments(
				uploadedDocuments.filter((_, index) => idxToRemove !== index)
			);
		},
		[setFolderDocuments, uploadedDocuments]
	);

	const minHClass = readonly ? "min-h-12" : "min-h-[3.75rem] py-2";
	const folderDocumentListItemClass = useMemo(
		() => clsx(folderDocumentListItemClassBase, minHClass),
		[readonly]
	);
	return (
		<div
			className={clsx(
				"flex w-full flex-col items-center justify-start",
				"overflow-hidden rounded-lg border border-neutral-high-200"
			)}
			id={`document-folder-${folderType}`}
			onDragOver={(evt) => {
				if (readonly) return;
				evt.preventDefault();
			}}
			onDrop={(evt) => {
				if (readonly) return;
				handleDrop(evt);
				setExpanded(true);
			}}
		>
			<header
				className={clsx(
					"flex w-full flex-row items-center justify-between px-4 py-2 min-h-14 text-sm",
					minHClass,
					{ expanded }
				)}
			>
				<div className="flex flex-row items-center gap-4">
					<div className="flex items-center justify-center h-5 w-5">
						<IcoFolder size="20" />
					</div>
					<div className="flex flex-col justify-center h-full text-left gap-1">
						<span>
							<span>{folderName}</span>
						</span>
						{!readonly && (
							<span className="text-xs">
								{expectedFormats ? (
									<span>Necessário anexar arquivo em {expectedFormats} </span>
								) : (
									<span>
										Necessário anexar a quantidade mínima de arquivos{" "}
									</span>
								)}
								{minDocumentCount !== undefined && (
									<span>
										{`(${
											uploadedDocuments.filter((doc) => !doc.error).length
										} de ${minDocumentCount})`}
									</span>
								)}
							</span>
						)}
					</div>
				</div>
				<div className="flex flex-row items-center gap-2 h-full">
					{!readonly && (
						<MultiFileInput
							handleDrop={(evt) => {
								handleDrop(evt);
								setExpanded(true);
							}}
							handleInputChange={(evt) => {
								handleInputChange(evt);
								setExpanded(true);
							}}
							buttonHierarchy="ghost"
							wrapperClassName="h-full"
							buttonClassName="h-full p-2 min-w-0 header-button"
						>
							<IcoAdd />
						</MultiFileInput>
					)}
					<Button
						kind="icon"
						hierarchy="ghost"
						className="header-button"
						onClick={() => setExpanded(!expanded)}
					>
						<div
							className={clsx("w-full h-full transition", {
								"rotate-[-180deg]": expanded
							})}
						>
							<IcoArrowDown />
						</div>
					</Button>
				</div>
			</header>
			{!!touched && !!errors && (
				<div
					className={clsx(
						"w-full flex items-center justify-left px-4 py-2 border-t border-neutral-high-200",
						"text-feedback-negative-pure-500"
					)}
				>
					<span className="text-sm">{errorMessage}</span>
				</div>
			)}
			{expanded && (
				<ol className="flex flex-col items-center w-full">
					{uploadedDocuments.map((uploadedDoc, idx) => (
						<li
							className={folderDocumentListItemClass}
							key={uploadedDoc.name + idx}
						>
							<FolderDocumentDisplay
								fileExtensions={fileExtensions}
								documentName={uploadedDoc.name}
								blobName={uploadedDoc.blobName}
								error={uploadedDoc.error}
								state={
									readonly
										? "readonly"
										: uploadedDoc.error
										? "error"
										: "success"
								}
								removeDocument={() => removeDocument(idx)}
							/>
						</li>
					))}

					{notUploadedDocuments.map((notUploadedDoc, idx) => (
						<li
							className={folderDocumentListItemClass}
							key={notUploadedDoc.name + idx}
						>
							<FolderDocumentDisplay
								fileExtensions={fileExtensions}
								documentName={notUploadedDoc.name}
								state={readonly ? "readonly" : "queued"}
								cancelUpload={() => cancelUpload(idx)}
							/>
						</li>
					))}

					{documentUploads.map((docUpload, idx) => (
						<li
							className={folderDocumentListItemClass}
							key={docUpload.name + idx}
						>
							<FolderDocumentDisplay
								documentName={docUpload.name}
								state={readonly ? "readonly" : "uploading"}
								uploadProgress={uploadProgress}
							/>
						</li>
					))}
				</ol>
			)}
		</div>
	);
}
