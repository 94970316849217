import { Application } from "models/Application";
import { ApplicationData } from "pages/NewApplication/steps/types";
import { BaseServices } from "./baseServices";
import {
	AnalysisEventDocument,
	DocumentFolderTypesAndFileExtensions
} from "./types";

interface FileExtensionData {
	extension: string;
	label: string;
	mediaType: string;
}

export interface DocumentTypeDetails {
	id: number;
	name: string;
	category: string;
	categoryLabel: string;
	details: string;
	documentFormat: string;
}

interface DocumentTemplateData {
	id: number;
	blobName: string;
	description: string;
}

interface ApplicationDocumentTypeData {
	id: string;
	requestedAt: string;
	alwaysRequired: boolean;
	documentType: DocumentTypeDetails;
	documentTemplates: DocumentTemplateData[];
}

export interface DocumentTypeListResponse {
	fileExtensions: FileExtensionData[];
	documentTypes: ApplicationDocumentTypeData[];
}

type DocumentContainerName = "documents" | "templates";

export class ApplicationDocumentService extends BaseServices<
	ApplicationData,
	Application
> {
	static instance = new ApplicationDocumentService();

	static readonly baseEndpoint = "blobs";
	static readonly baseDocumentFolderTypesEndpoint = "/document-folder-types";
	static readonly defaultContainerName: DocumentContainerName = "documents";

	constructor() {
		super(Application);
	}

	async download(
		blobName: string,
		containerName?: DocumentContainerName
	): Promise<{ downloadUrl: string }> {
		const encodedName = encodeURIComponent(blobName);
		const container =
			containerName || ApplicationDocumentService.defaultContainerName;
		return this.get(
			`${ApplicationDocumentService.baseEndpoint}/${container}/download`,
			{
				params: { fileName: encodedName }
			}
		);
	}

	async batchDownload(
		blobNames: string[],
		containerName?: DocumentContainerName
	): Promise<{ downloadUrls: string[] }> {
		const encodedBlobNames = blobNames.map((blobName) =>
			encodeURIComponent(blobName)
		);
		const container =
			containerName || ApplicationDocumentService.defaultContainerName;
		return this.post(
			`${ApplicationDocumentService.baseEndpoint}/batch-download`,
			{ fileNames: encodedBlobNames, containerName: container }
		);
	}

	async listAnalysisEventDocuments(
		applicationId: string,
		analysisEventId: string
	) {
		const url = `/my-applications/${applicationId}/events/${analysisEventId}/documents`;
		return this.get<AnalysisEventDocument[]>(url);
	}

	async getDocumentFolderTypesByApplication(
		applicationId: string,
		requestedMoment?: string
	): Promise<DocumentFolderTypesAndFileExtensions> {
		const url = `${ApplicationDocumentService.baseDocumentFolderTypesEndpoint}/${applicationId}`;
		return this.get(
			url,
			requestedMoment ? { params: { requestedAt: requestedMoment } } : {}
		);
	}

	static getInstance() {
		return ApplicationDocumentService.instance;
	}
}
