import { IcoAccessibilityQuestionCircle } from "assets/icons";

import Button from "components/Button";
import { useMemo, useState } from "react";

import { FormikErrors, FormikTouched } from "formik";

import DocumentFolderInput from "components/DocumentFolderInput";
import { Tooltip } from "react-tooltip";
import {
	ApplicationFormDocumentFolder,
	DocumentFolderType,
	DocumentFolderTypesAndFileExtensions,
	FileExtensionData,
	RootFolder
} from "services/types";
import * as Yup from "yup";
import {
	CollapsibleSectionHeader,
	Section,
	SectionErrorMessage,
	SectionHeader,
	StepLoading
} from "../layout";
import { ApplicationFormik } from "../types";
import DocumentTemplateModal from "./DocumentTemplateModal";

export const applicationDocumentsSchema = Yup.array().of(
	Yup.object().shape({
		name: Yup.string().nullable().required(),
		blobName: Yup.string().nullable().required()
	})
);

export const applicationDocumentFolderSchema = Yup.object().shape({
	documents: applicationDocumentsSchema,
	interventionDocumentFolderType: Yup.string().required(),
	id: Yup.string().optional()
});

function ApplicationDocumentFolderVolumeSection({
	title,
	volume,
	volumeFolders,
	documentFolders,
	touched,
	errors,
	setFolderValue,
	fileExtensions
}: Readonly<{
	title: string;
	volume: RootFolder;
	volumeFolders: DocumentFolderType[];
	documentFolders: Record<string, ApplicationFormDocumentFolder>;
	touched?: FormikTouched<Record<string, ApplicationFormDocumentFolder>>;
	errors?: FormikErrors<Record<string, ApplicationFormDocumentFolder>>;
	setFolderValue: (newValue: ApplicationFormDocumentFolder) => void;
	fileExtensions: FileExtensionData[] | null;
}>): JSX.Element {
	const [isVolumeSectionExpanded, setIsVolumeSectionExpanded] =
		useState<boolean>(true);
	const [isVolumeModalOpen, setIsVolumeModalOpen] = useState<boolean>(false);
	const requiredDocuments = useMemo(
		() => volumeFolders.filter((folderType) => folderType.alwaysRequired),
		[volumeFolders]
	);
	const optionalDocuments = useMemo(
		() => volumeFolders.filter((folderType) => !folderType.alwaysRequired),
		[volumeFolders]
	);
	const anchorHelpTooltip = useMemo(
		() => `optional-documents-help-${volume}`,
		[volume]
	);

	return (
		<Section>
			<CollapsibleSectionHeader
				title={title}
				expanded={isVolumeSectionExpanded}
				setExpanded={setIsVolumeSectionExpanded}
			/>
			{!!requiredDocuments.length && (
				<Button
					hierarchy="ghost"
					kind="link"
					size="small"
					className="w-fit my-4"
					onClick={() => setIsVolumeModalOpen(true)}
				>
					Consulte os documentos obrigatórios
				</Button>
			)}
			{isVolumeSectionExpanded && !!Object.keys(documentFolders).length && (
				<div className="flex flex-col gap-8 w-full">
					<div className="flex flex-col gap-8 w-full">
						{requiredDocuments.map((folderType) => {
							return (
								<DocumentFolderInput
									key={folderType.id}
									folderName={folderType.documentType.name}
									expectedFormats={folderType.expectedFormatsText}
									folderType={folderType.id}
									minDocumentCount={folderType.minAmountDocuments}
									value={documentFolders[folderType.id]}
									setValue={setFolderValue}
									errors={errors?.[folderType.id]}
									touched={touched?.[folderType.id]}
									fileExtensions={fileExtensions}
								/>
							);
						})}
					</div>
					{optionalDocuments && (
						<div className="flex flex-col gap-6 w-full">
							<div className="flex flex-row items-center w-full gap-1">
								<h3 className="text-base"> Documentos complementares </h3>
								<Tooltip
									anchorSelect={`#${anchorHelpTooltip}`}
									place="right"
									className="max-w-[330px]"
								>
									Documentos complementares podem ser obrigatórios, dependendo
									do tipo de solicitação. Verifique os documentos.
								</Tooltip>
								<div className="cursor-pointer" id={anchorHelpTooltip}>
									<IcoAccessibilityQuestionCircle />
								</div>
							</div>
							<Button
								hierarchy="ghost"
								kind="link"
								size="small"
								className="w-fit"
								onClick={() => setIsVolumeModalOpen(true)}
							>
								Consulte os documentos obrigatórios
							</Button>
							<div className="flex flex-col gap-8 w-full">
								{optionalDocuments.map((folderType) => {
									return (
										<DocumentFolderInput
											key={folderType.id}
											folderName={folderType.documentType.name}
											expectedFormats={folderType.expectedFormatsText}
											folderType={folderType.id}
											minDocumentCount={folderType.minAmountDocuments}
											value={documentFolders[folderType.id]}
											setValue={setFolderValue}
											errors={errors?.[folderType.id]}
											touched={touched?.[folderType.id]}
											fileExtensions={fileExtensions}
										/>
									);
								})}
							</div>
						</div>
					)}
				</div>
			)}
			<DocumentTemplateModal
				isOpen={isVolumeModalOpen}
				setIsOpen={setIsVolumeModalOpen}
				documents={volumeFolders}
				sectionTitle={title}
			/>
		</Section>
	);
}

export default function ApplicationDocumentsStep({
	formik,
	documentFolderTypesData,
	isLoadingFolderTypes
}: Readonly<ApplicationFormik> & {
	documentFolderTypesData: DocumentFolderTypesAndFileExtensions | null;
	isLoadingFolderTypes: boolean;
}): JSX.Element {
	const firstVolumeFolders = useMemo(
		() =>
			documentFolderTypesData?.documentFolderTypes.filter(
				(folderType) => folderType.rootFolder === RootFolder.VOLUME_1
			) ?? [],
		[documentFolderTypesData?.documentFolderTypes]
	);
	const secondVolumeFolders = useMemo(
		() =>
			documentFolderTypesData?.documentFolderTypes.filter(
				(folderType) => folderType.rootFolder === RootFolder.VOLUME_2
			) ?? [],
		[documentFolderTypesData?.documentFolderTypes]
	);

	const { values, setFieldValue, setFieldTouched, errors, touched } = formik;
	const foldersErrorMessage =
		typeof errors.documentFolders === "string" ? errors.documentFolders : null;
	const { documentFolders } = values;
	const setFolderDocumentsValue = (
		newFolderValue: ApplicationFormDocumentFolder
	) => {
		const fieldKey = `documentFolders.${newFolderValue.interventionDocumentFolderType}.documents`;
		setFieldTouched(fieldKey, true);
		return setFieldValue(fieldKey, newFolderValue.documents);
	};

	return (
		<div className="flex flex-col overflow-y-auto p-8 gap-8 h-full max-w-2xl">
			<Section>
				<SectionHeader
					title="Documentos do processo"
					description="Anexe os documentos solicitados em seus respectivos campos."
				/>
			</Section>
			{isLoadingFolderTypes && <StepLoading />}
			{!isLoadingFolderTypes && (
				<>
					{touched.documentFolders && foldersErrorMessage && (
						<SectionErrorMessage error={foldersErrorMessage} />
					)}
					{firstVolumeFolders.length > 0 && (
						<ApplicationDocumentFolderVolumeSection
							title="Volume 1 - Relatórios técnicos e documentos de identificação"
							volume={RootFolder.VOLUME_1}
							volumeFolders={firstVolumeFolders}
							documentFolders={documentFolders}
							setFolderValue={setFolderDocumentsValue}
							touched={formik.touched.documentFolders}
							errors={formik.errors.documentFolders}
							fileExtensions={documentFolderTypesData?.fileExtensions ?? null}
						/>
					)}
					{secondVolumeFolders.length > 0 && (
						<ApplicationDocumentFolderVolumeSection
							title="Volume 2 - Projeto e cronograma"
							volume={RootFolder.VOLUME_2}
							volumeFolders={secondVolumeFolders}
							documentFolders={documentFolders}
							setFolderValue={setFolderDocumentsValue}
							touched={formik.touched.documentFolders}
							errors={formik.errors.documentFolders}
							fileExtensions={documentFolderTypesData?.fileExtensions ?? null}
						/>
					)}
					{touched.documentFolders && foldersErrorMessage && (
						<SectionErrorMessage error={foldersErrorMessage} />
					)}
				</>
			)}
		</div>
	);
}
