import clsx from "clsx";
import DocumentChip from "components/DocumentChip";
import { baseConfig } from "config";
import { DocumentUpload } from "hooks/useFileUploadQueue";
import { DocumentLike } from "services/types";
import { TailwindGridCols, TailwindMdGridCols } from "styles/helpers";

export default function MultiFileDisplay({
	documentsUploaded,
	documentsInUpload,
	documentsNotUploaded,
	setDocumentsUploaded,
	setDocumentsNotUploaded,
	showFileCountLimit,
	columnsDisplay
}: {
	documentsUploaded: DocumentLike[];
	documentsInUpload: DocumentUpload[];
	documentsNotUploaded: File[];
	setDocumentsUploaded: (newValue: DocumentLike[]) => void;
	setDocumentsNotUploaded: (newValue: File[]) => void;
	showFileCountLimit: boolean;
	columnsDisplay?: 4 | 5;
}) {
	const fileCount =
		documentsUploaded.length +
		documentsNotUploaded.length +
		documentsInUpload.length;

	return !!fileCount || showFileCountLimit ? (
		<>
			<div className="flex items-center justify-between text-sm top-0">
				<span>
					{`${fileCount} arquivo${fileCount > 1 ? "s" : ""} (máximo de ${
						baseConfig.maxSubmissionApplicationFiles
					})`}
				</span>
				{showFileCountLimit && (
					<span className="text-feedback-negative-pure-500">
						Você selecionou arquivos demais
					</span>
				)}
			</div>
			<div
				className={clsx(
					"grid gap-3 md:overflow-auto scrollbar-width--thin",
					TailwindMdGridCols[columnsDisplay ?? 4],
					TailwindGridCols[columnsDisplay ?? 2]
				)}
			>
				{documentsUploaded.map((doc, idx) => (
					<DocumentChip
						key={`${doc.blobName ?? doc.name}-${idx}`}
						eventDocument={doc}
						uploadError={doc.error}
						removeDocument={() =>
							setDocumentsUploaded(
								documentsUploaded.filter((_, idx2) => idx !== idx2)
							)
						}
						showChipLabel
					/>
				))}
				{documentsInUpload.map((docUp, idx) => (
					<DocumentChip
						key={`${docUp.name}-${idx}`}
						eventDocument={{ blobName: "", name: docUp.name }}
						isLoading
						showChipLabel
					/>
				))}
				{documentsNotUploaded
					.filter(
						(file) =>
							!documentsInUpload.find((docUp) => docUp.name === file.name)
					)
					.map((file, idx) => (
						<DocumentChip
							key={`${file.name}-${idx}`}
							eventDocument={{ blobName: "", name: file.name }}
							cancelUpload={() =>
								setDocumentsNotUploaded(
									documentsNotUploaded.filter((_, idx2) => idx2 !== idx)
								)
							}
							isWaiting
							showChipLabel
						/>
					))}
			</div>
		</>
	) : null;
}
