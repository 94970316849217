import { ModalRedirect } from "components";
import AuthTokenHelper from "helpers/AuthTokenHelper";
import useUserInfoResponseHandler from "hooks/useUserInfoResponseHandler";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { UserInfoService } from "services/userInfo";

export default function AADB2CConfirmLogin() {
	const location = useLocation();
	const userInfoService = new UserInfoService();
	const navigate = useNavigate();

	const handleResponse = useUserInfoResponseHandler();

	const confirmLogin = async () => {
		if (location.hash) {
			const hashToken = location.hash;
			if (hashToken.includes("#id_token")) {
				const token = window.location.hash.substring(
					location.hash.indexOf("#id_token=") + 10
				);
				AuthTokenHelper.setAuthToken(token);
			}
		}

		try {
			const response = await userInfoService.verifyUser();
			handleResponse(response);
		} catch (error) {
			toast.error("Erro no login! Entre em contato com o nosso suporte");
			navigate("/");
		}
	};

	useEffect(() => {
		setTimeout(() => {
			confirmLogin();
		}, 1000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<ModalRedirect
				isOpen
				text="Estamos redirecionando você para o sistema da CCR Faixa de Domínio."
			/>
		</div>
	);
}
