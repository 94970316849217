function bool(name: string) {
	return process.env[name]?.toLocaleLowerCase() === "true";
}

const baseConfig = {
	baseURL: process.env.REACT_APP_API_URL,
	baseURLDomain: process.env.REACT_APP_COOKIE_DOMAIN ?? "",
	imageURL: process.env.REACT_APP_IMAGE_SOURCE_URL,
	mockRequest: bool("REACT_APP_API_MOCK"),
	b2cUrl: process.env.REACT_APP_B2C_URL,
	b2cClientId: process.env.REACT_APP_B2C_CLIENT_ID,
	systemEnabled: bool("REACT_APP_SYSTEM_ENABLED"),
	maxSubmissionApplicationFiles: parseInt(
		process.env.REACT_APP_MAX_SUBMISSION_APPLICATION_FILES || "500",
		10
	)
};

export { baseConfig };
