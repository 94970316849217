import { AxiosResponse, HttpStatusCode } from "axios";
import AuthTokenHelper from "helpers/AuthTokenHelper";
import { UserInfo } from "models/auth/UserInfo";
import { UserVerifyReturn } from "models/auth/types";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setLoggedIn } from "store/features/auth/slice";

export default function useUserInfoResponseHandler(): (
	response: AxiosResponse<UserVerifyReturn>
) => void {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	return useCallback(
		(response: AxiosResponse<UserVerifyReturn>) => {
			const tokenCookie = AuthTokenHelper.getCookieToken();
			if (tokenCookie) {
				AuthTokenHelper.setAuthToken(tokenCookie);
			}

			if (
				response.status !== HttpStatusCode.Ok &&
				response.status !== HttpStatusCode.Accepted
			) {
				toast.error("Erro no login! Entre em contato com o nosso suporte");
				return;
			}

			const { data } = response;
			const { userName, ...rest } = data;
			const userData = { name: userName, ...rest };
			const userInfo = new UserInfo(userData);

			dispatch(setLoggedIn({ user: userInfo }));
			const navigateDestination =
				response.status === HttpStatusCode.Accepted ? "/cadastro" : "/";
			navigate(navigateDestination);
		},
		[dispatch, navigate]
	);
}
